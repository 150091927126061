<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/order/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item label="关键字" prop="name">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="keyword"
          @change="getKeyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
        <el-button type="primary" @click="exportExcel">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="nickname"  label="昵称">
        <template slot-scope="scope">
          {{scope.row.userDTO.nickname}}
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机">
        <template slot-scope="scope" v-if="scope.row.userDTO.phone">
          {{scope.row.userDTO.phone}}
        </template>
      </el-table-column>
      <el-table-column label="产品名称">
        <template slot-scope="scope">
          {{scope.row.deviceDTO.name}}
        </template>
      </el-table-column>
      <el-table-column label="产品图">
        <template slot-scope="scope">
        <el-image v-if="scope.row.deviceDTO.imageUrl"
          style="width: 100px; height: 100px"
          :src="scope.row.deviceDTO.imageUrl"
          :fit="scale-down"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="添加时间">
        <template slot-scope="scope">
          {{scope.row.createdAt|formatDate('yyyy-M-d H:m:s')}}
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
          @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
            详情
          </el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      tableData: [],
      statistics:{},
      crumb:['用户设备','列表']
    };
  },
  methods: {
    exportExcel() {
      let _data = {};
      if (this.keyword.length > 0) {
        _data.keyword = this.keyword;
      }
     
      operate.exportUserDevice(_data).then((res) => {
        let fileUrl = window.URL.createObjectURL(new Blob([res],{type:"application/vnd.ms-excel;charset=UTF-8"}));
        let a = document.createElement("a");
        a.style.display = "none";
        a.href = fileUrl;
        a.setAttribute("download", `用户设备.xlsx`); // 注意文件后缀
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      });
    },
    searchput() {
      this.getList(1)
    },
  
    getList(p) {
      let data={}
      if(this.keyword.length>0){
        data={ pageIndex: p, nickname: this.keyword }
      }else{
        data={ pageIndex: p}
      }
      operate.getUserDeviceList(data).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    },
  },
  mounted() {
    operate.getUserDeviceList({ pager: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
  },
};
</script>