<template>
  <div class="right-content">
    <Breadcrumb
      :crumb="crumb"
      addpath="/order/add"
      :showButton="false"
    ></Breadcrumb>
    <el-form>
      <el-form-item>
        <el-date-picker
          v-model="start_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择开始时间"
          style="width: 240px;margin-bottom:5px;"
        >
        </el-date-picker>
        <span> - </span>
        <el-date-picker
          class="input-width"
          v-model="end_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择结束时间"
          style="width: 240px;margin-bottom:5px;"
        >
        </el-date-picker>
        <el-input
          placeholder="请输入省"
          clearable
          size="small"
          style="width: 240px"
          v-model="sheng"
        ></el-input>
        <el-input
          placeholder="请输入市"
          clearable
          size="small"
          style="width: 240px;margin-bottom:5px;"
          v-model="shi"
        ></el-input>
        <el-input
          placeholder="请输入设备名称"
          clearable
          size="small"
          style="width: 240px;margin-bottom:5px;"
          v-model="device_name"
        ></el-input>
        <el-input
          placeholder="请输入设备编码"
          clearable
          size="small"
          style="width: 240px;margin-bottom:5px;"
          v-model="device_code"
        ></el-input>
        <el-input
          placeholder="请输入设备型号"
          clearable
          size="small"
          style="width: 240px;margin-bottom:5px;margin-right:5px;"
          v-model="device_type"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
        <el-button type="primary" @click="exportExcel">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="userId" label="用户ID"></el-table-column>
      <el-table-column prop="userPhone" label="用户手机"> </el-table-column>

      <el-table-column prop="deviceId" label="设备ID"> </el-table-column>
      <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column prop="deviceType" label="设备型号"> </el-table-column>
      <el-table-column prop="deviceCode" label="设备编码"> </el-table-column>
      <el-table-column prop="func" label="功能">
        <template slot-scope="scope">
          <div v-if="scope.row.func == 1">一档风</div>
          <div v-if="scope.row.func == 2">二档风</div>
          <div v-if="scope.row.func == 3">三档风</div>
          <div v-if="scope.row.func == 4">定时</div>
          <div v-if="scope.row.func == 5">左右摇头</div>
          <div v-if="scope.row.func == 6">360摇头</div>
        </template>
      </el-table-column>
      <el-table-column prop="temperature" label="温度"> </el-table-column>
      <el-table-column label="时间">
        <template slot-scope="scope">
          {{ scope.row.createdAt | formatDate("yyyy-M-d H:m:s") }}
        </template>
      </el-table-column>
      <el-table-column prop="sheng" label="省"> </el-table-column>
      <el-table-column prop="shi" label="市"> </el-table-column>
      <el-table-column prop="qu" label="区/县"> </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      loading: false,
      start_date: "",
      end_date: "",
      device_name: "",
      device_code: "",
      device_type: "",
      sheng:"",
      shi:"",
      total: 0,
      tableData: [],
      statistics: {},
      crumb: ["操作日志", "列表"],
    };
  },
  methods: {
    exportExcel() {
      let _data = {};
      if (this.device_name.length > 0) {
        _data.deviceName = this.device_name;
      }
      if (this.device_code.length > 0) {
        _data.deviceCode = this.device_code;
      }
      if (this.device_type.length > 0) {
        _data.deviceType = this.device_type;
      }
      if (this.start_date.length > 0) {
        _data.createStartAt = this.start_date;
      }
      if (this.end_date.length > 0) {
        _data.createEndAt = this.end_date;
      }
      if(this.sheng.length>0){
       data.sheng=this.sheng
      }
      if(this.shi.length>0){
       _data.shi=this.shi
      }
      _data.responseType='blob';
      operate.exportOperateLog(_data).then((res) => {
        let fileUrl = window.URL.createObjectURL(new Blob([res],{type:"application/vnd.ms-excel;charset=UTF-8"}));
        let a = document.createElement("a");
        a.style.display = "none";
        a.href = fileUrl;
        a.setAttribute("download", `操作记录.xlsx`); // 注意文件后缀
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      });
    },
    searchput() {
      this.getList(1);
    },
    getKeyword() {},
    getList(p) {
      let _data = {};
      if (this.device_name.length > 0) {
        _data.deviceName = this.device_name;
      }
      if (this.device_code.length > 0) {
        _data.deviceCode = this.device_code;
      }
      if (this.device_type.length > 0) {
        _data.deviceType = this.device_type;
      }
      if (this.start_date.length > 0) {
        _data.createStartAt = this.start_date;
      }
      if (this.end_date.length > 0) {
        _data.createEndAt = this.end_date;
      }
      if(this.sheng.length>0){
        _data.sheng=this.sheng
      }
      if(this.shi.length>0){
        _data.shi=this.shi
      }
      operate.getOperateLog(_data).then((response) => {
        this.tableData = response.data;
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getOperateLog({ pager: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
  },
};
</script>